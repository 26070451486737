<template>
  <div class='home'>
    <img class="banner" src="../assets/img/banner.png">

    <div class="bg"></div>

    <div class="pic">
      <div class="pic1" @click="goToPath(1)">产业学院中心</div>
      <div class="pic2" @click="goToPath(2)">中外办学中心</div>
      <div class="pic3" @click="goToPath(3)">高校集采中心</div>
      <div class="pic4" @click="goToPath(4)">教育投资基金</div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {

    }
  },
  created() {
  },
  methods: {
    goToPath(key) {
      switch (key) {
        case 3:
          window.open('https://portal.xiaoyuanshenghuowang.com/')
          break;
      }
    },
  },
}
</script>

<style scoped lang='scss'>
.home {

  .banner {
    width: 100%;
    height: 558px;
    display: block;
    object-fit: cover;
    -webkit-user-drag: none;
  }

  .pic {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 1280px;
    margin: 50px auto 0;
  }

  .bg {
    position: absolute;
    width: 100%;
    height: 460px;
    background: url(../assets/img/footer.png) no-repeat;
    background-size: cover;
    background-position: 100%;
  }

  .pic1 {
    background: url(../assets/img/home1.png) no-repeat;
  }

  .pic2 {
    background: url(../assets/img/home2.png) no-repeat;
    margin-top: 60px;
  }

  .pic3 {
    background: url(../assets/img/home3.png) no-repeat;
  }

  .pic4 {
    background: url(../assets/img/home4.png) no-repeat;
    margin-top: 60px;
  }

  .pic1,
  .pic2,
  .pic3,
  .pic4 {
    position: relative;
    width: 304px;
    height: 400px;
    color: #fff;
    font-size: 25px;
    padding: 345px 20px 20px;
    text-align: center;
    background-size: cover;
    background-position: 100%;
    box-sizing: border-box;
    cursor: pointer;
  }
}
</style>
